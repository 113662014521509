import React from 'react';
import styled from 'styled-components';

// Components
import SEO from "../components/seo";
import Layout from "../layouts/index";

const PageContainer = styled.div`

    background-color: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    max-width: 1440px;
    margin: 0 auto;

    h1 {
        color: #333;
        text-align: center;
    }
    h2 {
        color: #2c5282;
        margin-top: 30px;
        padding-bottom: 10px;
        border-bottom: 2px solid #e2e8f0;
    }
    h3 {
        color: #4a5568;
        margin-top: 20px;
    }
    .section {
        margin-bottom: 40px;
    }
    .question-group {
        margin-bottom: 25px;
    }
    label {
        display: block;
        margin-bottom: 8px;
        font-weight: bold;
        color: #4a5568;
    }
    .helper-text {
        font-size: 0.9em;
        color: #718096;
        margin-bottom: 5px;
        font-style: italic;
    }
    textarea {
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid #cbd5e0;
        border-radius: 4px;
        box-sizing: border-box;
        min-height: 100px;
        resize: vertical;
    }
    button {
        background-color: #4299e1;
        color: white;
        padding: 12px 20px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 16px;
        display: block;
        margin: 20px auto;
    }
    button:hover {
        background-color: #3182ce;
    }
    .intro-text {
        font-style: italic;
        color: #666;
        margin-bottom: 30px;
        text-align: center;
    }
`

const SaasAnalyticsAndConversionTrackingQuestionnaire = () => {



const handleSubmit = (e) => {
    e.preventDefault();

    const form = document.getElementById("analyticsForm");
    const formData = new FormData(form);

    fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
    })
        .then(() => {
        alert("Form submitted successfully!");
        form.reset();
        })
        .catch((error) => {
        alert("Error submitting form");
        console.error("Form submission error:", error);
        });
    };
  return (
    <Layout>
      <SEO 
        description="Unlock insights with our SaaS Analytics and Conversion Tracking Questionnaire. Identify key metrics, optimize conversion strategies, and enhance your SaaS performance with tailored analytics solutions."
        title="SaaS Analytics and Conversion Tracking Questionnaire | Full Funnel Growth" 
      />
      <PageContainer>
        <h1>SaaS Analytics and Conversion Tracking Questionnaire</h1>
        <p class="intro-text">This questionnaire is designed to gather comprehensive information about your company's technical setup, business goals, and analytics requirements to build a customized analytics and conversion tracking strategy.</p>
        
        <form
        id="analyticsForm"
        name="analyticsForm"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
        >
        <input type="hidden" name="form-name" value="analyticsForm" />
        <input type="hidden" name="bot-field" />

        <div className="section">
            <h2>I. Core Business Objectives</h2>
            
            <div className="question-group">
            <label>What are your primary business goals over the next 12 months?</label>
            <textarea name="what_are_your_primary_business_goals_over_the_next_12_months" placeholder="e.g., Increase user engagement, expand product line" required></textarea>
            </div>

            <div className="question-group">
            <label>What are the main objectives for user growth and engagement?</label>
            <textarea name="what_are_the_main_objectives_for_user_growth_and_engagement" placeholder="e.g., Double user base, enhance retention rate"></textarea>
            </div>

            <div className="question-group">
            <label>Are there any specific strategic initiatives driving these goals?</label>
            <textarea name="are_there_any_specific_strategic_initiatives_driving_these_goals" placeholder="e.g., product expansion, market penetration"></textarea>
            </div>
            
            <h2>2. Key Performance Indicators (KPIs)</h2>
            
            <div className="question-group">
            <label>What are the top KPIs used to measure success?</label>
            <textarea name="what_are_the_top_kpis_used_to_measure_success" placeholder="e.g., Monthly Active Users, Customer Acquisition Cost"></textarea>
            </div>

            <div className="question-group">
            <label>Are there specific metrics that leadership or investors consider essential?</label>
            <textarea name="are_there_specific_metrics_that_leadership_or_investors_consider_essential"></textarea>
            </div>

            <div className="question-group">
            <label>How often are KPIs reviewed, and who is responsible for overseeing them?</label>
            <textarea name="how_often_are_kpis_reviewed_and_who_is_responsible_for_overseeing_them" placeholder="e.g., monthly, overseen by growth team"></textarea>
            </div>
            
            <h2>3. Investor and Stakeholder Needs</h2>
            
            <div className="question-group">
            <label>Are there any investor reporting requirements? If so, what metrics are essential for updates?</label>
            <textarea name="are_there_any_investor_reporting_requirements_if_so_what_metrics_are_essential_for_updates" placeholder="e.g., quarterly reporting on LTV, churn rate"></textarea>
            </div>

            <div className="question-group">
            <label>Do stakeholders require specific reports or dashboards to monitor performance?</label>
            <textarea name="do_stakeholders_require_specific_reports_or_dashboards_to_monitor_performance"></textarea>
            </div>

            <div className="question-group">
            <label>How frequently do you report performance metrics to stakeholders or investors?</label>
            <textarea name="how_frequently_do_you_report_performance_metrics_to_stakeholders_or_investors"></textarea>
            </div>

            <h2>4. Customer Segmentation and Targeting</h2>

            <div className="question-group">
            <label>Who are your primary customer segments?</label>
            <textarea name="who_are_your_primary_customer_segments" placeholder="e.g., industry, company size, role"></textarea>
            </div>

            <div className="question-group">
            <label>Are there any specific segments or personas prioritized for growth?</label>
            <textarea name="are_there_any_specific_segments_or_personas_prioritized_for_growth"></textarea>
            </div>

            <div className="question-group">
            <label>How do you currently track and measure customer lifetime value (LTV) across segments?</label>
            <textarea name="how_do_you_currently_track_and_measure_customer_lifetime_value_ltv_across_segments"></textarea>
            </div>
        </div>

        <div className="section">
            <h2>II. Website Infrastructure</h2>

            <div className="question-group">
            <label>What frontend framework does your website use?</label>
            <textarea name="what_frontend_framework_does_your_website_use" placeholder="e.g., React, Vue, Angular"></textarea>
            </div>

            <div className="question-group">
            <label>Which content management system, if any, do you use?</label>
            <textarea name="which_content_management_system_if_any_do_you_use" placeholder="e.g., WordPress, Contentful, Webflow"></textarea>
            </div>

            <div className="question-group">
            <label>Where is your website hosted?</label>
            <textarea name="where_is_your_website_hosted" placeholder="e.g., AWS, Netlify, Vercel"></textarea>
            </div>

            <div className="question-group">
            <label>Do you have separate environments for development, staging, and production?</label>
            <textarea name="do_you_have_separate_environments_for_development_staging_and_production"></textarea>
            </div>
        </div>

        <div className="section">
            <h2>III. Product Infrastructure</h2>

            <div className="question-group">
            <label>What frontend framework powers your product?</label>
            <textarea name="what_frontend_framework_powers_your_product" placeholder="e.g., React, Angular, Flutter"></textarea>
            </div>

            <div className="question-group">
            <label>Which backend language or framework do you use?</label>
            <textarea name="which_backend_language_or_framework_do_you_use" placeholder="e.g., Node.js, Ruby on Rails, Django"></textarea>
            </div>

            <div className="question-group">
            <label>Do you have mobile applications? If so, are they for iOS, Android, or both?</label>
            <textarea name="do_you_have_mobile_applications_if_so_are_they_for_ios_android_or_both"></textarea>
            </div>
            
            <div className="question-group">
            <label>How do users authenticate with your product?</label>
            <textarea name="how_do_users_authenticate_with_your_product"></textarea>
            </div>

            <div className="question-group">
            <label>Do you support single sign-on (SSO)? Which providers?</label>
            <textarea name="do_you_support_single_sign_on_sso_which_providers"></textarea>
            </div>

            <div className="question-group">
            <label>Do you support social logins (e.g., Google, Facebook, LinkedIn)?</label>
            <textarea name="do_you_support_social_logins"></textarea>
            </div>
        </div>

        <div className="section">
            <h2>IV. Current Analytics Stack</h2>

            <div className="question-group">
            <label>Which website analytics tools are you using?</label>
            <textarea name="which_website_analytics_tools_are_you_using" placeholder="e.g., Google Analytics, Mixpanel, Amplitude"></textarea>
            </div>

            <div className="question-group">
            <label>Which product analytics tools are in place?</label>
            <textarea name="which_product_analytics_tools_are_in_place" placeholder="e.g., Segment, PostHog"></textarea>
            </div>

            <div className="question-group">
            <label>What marketing analytics platforms do you use?</label>
            <textarea name="what_marketing_analytics_platforms_do_you_use" placeholder="e.g., Google Ads, Facebook Pixel, HubSpot"></textarea>
            </div>

            <div className="question-group">
            <label>Are you using any CRM or customer success tools?</label>
            <textarea name="are_you_using_any_crm_or_customer_success_tools" placeholder="e.g., Salesforce, Zendesk"></textarea>
            </div>

            <div className="question-group">
            <label>Are there any data accuracy concerns?</label>
            <textarea name="are_there_any_data_accuracy_concerns"></textarea>
            </div>
        </div>

        <button type="submit">Submit Questionnaire</button>
        </form>
      </PageContainer>
    </Layout>
  )
}

export default SaasAnalyticsAndConversionTrackingQuestionnaire